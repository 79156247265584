<template>
  <div class="card" :class="{'is-shadowless': !isModal}" id="useItem">
    <div class="card-content">
      <header>
        <div class="is-flex is-justify-content-space-between is-align-items-center mb-2">
          <h2 class="is-size-5 has-text-success has-text-weight-bold mr-3">{{ $t('warehouse.use_item') }}</h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
        <div>
          <p>{{ $t(`warehouse.${warehouse.category_name.toLowerCase()}`) }}</p>
          <h2 class="is-size-5">{{ warehouse.product_name }}</h2>
          <div class="is-flex is-align-items-center">
            <span class="is-size-7 mr-2" :class="{'has-text-success': warehouse.qty > 0, 'has-text-danger' : warehouse.qty <= 0}">
              {{ $number(warehouse.qty) }} {{ warehouse.unit }}
            </span>
            <span class="is-size-7 has-text-secondary">{{ warehouse.qty > 0 ? $t('warehouse.available') : $t('warehouse.empty') }}</span>
          </div>
        </div>
      </header>
      <section class="my-2">
        <div class="columns is-tablet">
          <div class="column is-5-tablet">
            <ValidationObserver ref="form">
              <form @submit.prevent="addData">
                <ValidationProvider :name="$t('farm.block')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('farm.block')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <sg-select type="is-secondary is-light" no-outline expanded :items="selectedFarm.blocks"
                               :placeholder="`${$t('select')} ${$t('farm.block')}`"
                               label="block_name"
                               @change="handleChangeBlock"
                               v-model="form.block"></sg-select>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider :name="$t('farm.pond')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('farm.pond')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <sg-select type="is-secondary is-light" v-model="form.ponds" :items="filteredPonds"
                               label="name"
                               :placeholder="`${$t('select')} ${$t('farm.pond')}`"
                               multiple expanded/>
                  </b-field>
                </ValidationProvider>

                <b-field>
                  <b-checkbox
                      :disabled="filteredPonds.length === 0"
                      v-model="to_all_ponds" @input="handleSelectAllPonds">
                    {{ $t('farm.to_all_ponds') }}
                  </b-checkbox>
                </b-field>

                <ValidationProvider :name="$t('warehouse.qty')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('warehouse.qty')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <p class="control w-100">
                      <b-input type="number" id="qty" step="any" v-model.number="form.qty"
                               placeholder="0.0" expanded></b-input>
                    </p>
                    <p class="control">
                      <b-button type="is-text" class="btn-placeholder">{{ warehouse.unit }}</b-button>
                    </p>
                  </b-field>
                </ValidationProvider>

                <sg-datetimepicker v-model="form.date" :label="$t('date')" class="is-block mb-3"/>

                <ValidationProvider :name="$t('desc')" rules="max:40" v-slot="{ errors }">
                  <b-field :label="$t('desc')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="text" id="description" v-model="form.desc"
                             :placeholder="$t('desc')"></b-input>
                  </b-field>
                </ValidationProvider>

                <div class="has-text-right">
                  <b-button type="is-primary" outlined icon-left="plus" @click="addData">{{ $t('add') }} Data</b-button>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="column is-7-tablet">
            <b-table :mobile-cards="false" :data="data" bordered class="mb-2">
              <template #empty>
                <p class="has-text-centered">{{ $t('no') }} Data</p>
              </template>

              <b-table-column field="no" label="No" v-slot="props">
                {{ props.index + 1 }}
              </b-table-column>

              <b-table-column field="pond" :label="$t('farm.pond')" v-slot="props">
                {{ props.row.pond.name }}
              </b-table-column>

              <b-table-column field="datetime" :label="$t('time')" v-slot="props">
                {{ $dateFormat(props.row.date) }}
                {{ $timeFormat(props.row.date) }}
              </b-table-column>

              <b-table-column field="qty" :label="$t('warehouse.qty')" v-slot="props">
                {{ props.row.qty }}
              </b-table-column>

              <b-table-column field="desc" :label="$t('desc')" v-slot="props">
                {{ props.row.desc }}
              </b-table-column>

              <b-table-column field="action" label="" centered v-slot="props">
                <b-button icon-left="trash-can-outline" type="is-danger" @click="deleteItem(props.index)"/>
              </b-table-column>
            </b-table>

            <div class="has-text-right">
              <b-button type="is-primary" @click="handleSubmit" class="is-gradient">{{ $t('save') }}</b-button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import SgDatetimepicker from "@/components/Sg/SgDatetimepicker";

export default {
  name: "UseItem",
  components: {SgDatetimepicker, SgSelect},
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    warehouse: {
      type: Object
    }
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      if (this.form.block && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.form.block.block_id && e.status >= 1)
      else return []
    },
  },
  watch: {
    'form.ponds': {
      deep: true,
      handler(value) {
        if (value.length > 0 && value.length === this.filteredPonds.length) {
          this.to_all_ponds = true
        } else {
          this.to_all_ponds = false
        }
      }
    }
  },
  data: () => {
    return {
      form: {
        show_preset: false,
        block: null,
        ponds: []
      },
      to_all_ponds: false,

      data: [],
    }
  },
  methods: {

    handleChangeBlock() {
      this.form.ponds = []
      this.to_all_ponds = false
    },

    addData() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          this.$loading()
          let date = this.$timestamp(this.form.date)

          for (let i = 0; i < this.form.ponds.length; i++) {
            let pond = this.form.ponds[i]
            this.data.push({
              pond: pond,
              block: this.form.block,
              date: date,
              qty: this.form.qty,
              desc: this.form.desc || '',
            })
          }
          this.resetForm()

          this.$loading(false)
        }
      })
    },

    deleteItem(index) {
      this.data.splice(index, 1)
    },

    async handleSubmit() {
      this.$loading()

      let ponds = this.data.map(e => e.pond)

      ponds = this._.cloneDeep(ponds.filter(this.$unique))

      for (let i = 0; i < ponds.length; i++) {
        let pond = ponds[i]
        pond.cycle = await this.$store.dispatch('farm/getActiveCycle', pond.pond_id)
      }

      let data = this.data.map(e => {
        let cycle = ponds.find(f => f.pond_id === e.pond.pond_id).cycle
        return {
          farm_id: this.warehouse.farm_id,
          pond_id: e.pond.pond_id,
          block_id: e.block.block_id,
          cycle_id: cycle.cycle_id,
          warehouse_id: this.warehouse.warehouse_id,
          component_id: 0,
          product_name: this.warehouse.product_name,
          product_price: this.warehouse.product_price,
          category_id: this.warehouse.category_id,
          mode: this.warehouse.mode,
          unit: this.warehouse.unit,
          created_at: e.date,
          qty: e.qty,
          desc: e.desc,
        }
      })

      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        await this.$store.dispatch('farm/useItem', item)
      }

      this.$store.dispatch('farm/refreshWarehouse')

      this.$loading(false)
      this.$emit('close')
    },

    resetForm() {
      this.form = {
        block: null,
        ponds: []
      }
      this.to_all_ponds = false
      this.$refs['form'].reset()
    },

    handleSelectAllPonds() {
      if (this.to_all_ponds) {
        this.form.ponds = this.filteredPonds.filter(e => e)
      } else {
        this.form.ponds = []
      }
    },
  }
}
</script>

<style scoped lang="scss">
.card#useItem {
  width: 1200px !important;
  max-width: 100%;
}
</style>

