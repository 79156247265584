<template>
  <ValidationProvider :name="$t('date')" :rules="{required: required}" v-slot="{ errors }">
    <b-field :label="label" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]" class="mb-0">
      <p class="control w-100">
        <b-datetimepicker
            append-to-body
            editable
            :placeholder="$t('date')"
            icon="calendar-text"
            locale="id-ID"
            v-model="date"
            @input="handleChangeValue"
        />
      </p>
      <p class="control">
        <b-button type="is-light" class="is-radiusless" @click="show_preset = !show_preset">
          <b-icon icon="cog-outline" size="is-small"/>
        </b-button>
      </p>
    </b-field>

    <b-collapse :open="show_preset" animation="slide">
      <div class="is-flex is-flex-wrap-wrap mt-2" v-if="time.length">
        <b-field v-for="(t, i) in time" :key="i" class="ml-2">
          <p class="control">
            <b-button size="is-small" type="is-primary" outlined rounded
                      @click="setPresetTime(t)">
              {{ t }}
            </b-button>
          </p>
          <p class="control">
            <b-button size="is-small" type="is-danger" outlined rounded @click="removeTime(i)">
              <b-icon icon="close" size="is-small"/>
            </b-button>
          </p>
        </b-field>
      </div>
      <b-button type="is-primary is-light" class="is-radiusless" size="is-small" expanded
                :disabled="!value"
                @click="addTime(value)">
        Save Preset Time
      </b-button>
    </b-collapse>
  </ValidationProvider>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "SgDatetimepicker",
  props: {
    value: Date,
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      date: null,
      show_preset: false,
    }
  },
  created() {
    this.date = this.value
  },
  watch: {
    value(value) {
      this.date = value
    }
  },
  computed: {
    ...mapState('setting', [
      'time'
    ]),
  },
  methods: {
    ...mapActions('setting', [
      'addTime',
      'removeTime',
    ]),

    setPresetTime(time) {
      this.$emit('input', new Date(this.$dateFormat(this.date) + ' ' + time))
    },

    handleChangeValue(value) {
      this.$emit('input', value)
    }
  }
}
</script>